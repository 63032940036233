<template>
  <div>
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Mashina registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group col-4">
            <label>Haydovchi nomi</label>
            <v-autocomplete
              outlined
              style="height: 40px"
              v-model="driver"
              :items="allDriversNames"
              item-text="full__name"
              no-data-text="Haydovchini kiriting"
              item-value="id"
              dense
            ></v-autocomplete>
          </div>
          <div class="form-group col-4">
            <label>Mashina modeli</label>
            <v-select
              :items="allTruckTypes"
              item-text="name"
              item-value="id"
              v-model="truck_type"
              outlined
              dense
              :error-messages="truck_typeErrors"
              @input="$v.truck_type.$touch()"
              @blur="$v.truck_type.$touch()"
            >
            </v-select>
          </div>
          <div class="form-group col-4">
            <label>Region raqami</label>
            <v-text-field
              outlined
              v-model="reg_number_region"
              type="text"
              dense
              v-mask="'##'"
              counter="2"
              :error-messages="reg_number_regionErrors"
              @input="$v.reg_number_region.$touch()"
              @blur="$v.reg_number_region.$touch()"
            ></v-text-field>
          </div>
          <div class="form-group col-4">
            <label>Mashina raqami</label>
            <v-text-field
              outlined
              dense
              @keydown.space.prevent
              v-model="reg_number"
              type="text"
              :error-messages="reg_numberErrors"
              @input="$v.reg_number.$touch()"
              @blur="$v.reg_number.$touch()"
            ></v-text-field>
          </div>
          <div class="form-group col-4">
            <label>Kuzov nomeri</label>
            <v-text-field
              outlined
              dense
              v-model="kuzov_number"
              type="text"
              :error-messages="kuzov_numberErrors"
              @input="$v.kuzov_number.$touch()"
              @blur="$v.kuzov_number.$touch()"
            ></v-text-field>
          </div>
          <div class="form-group col-4">
            <label>Kuzov turi</label>
            <v-text-field
              class="kuzov_type"
              outlined
              dense
              v-model="kuzov_type"
              type="text"
              :error-messages="kuzov_typeErrors"
              @input="$v.kuzov_type.$touch()"
              @blur="$v.kuzov_type.$touch()"
            ></v-text-field>
          </div>
          <div class="form-group col-4">
            <label>Dvigatel raqami</label>
            <v-text-field
              outlined
              dense
              v-model="dvigatel_number"
              type="text"
              :error-messages="dvigatel_numberErrors"
              @input="$v.dvigatel_number.$touch()"
              @blur="$v.dvigatel_number.$touch()"
            ></v-text-field>
          </div>
          <div class="form-group col-4">
            <label>Sotib olingan sanasi</label>

            <v-text-field
              v-model="bought_date"
              @input="$v.bought_date.$touch()"
              @blur="$v.bought_date.$touch()"
              :error-messages="bought_dateErrors"
              v-mask="'##-##-####'"
              dense
              outlined
            ></v-text-field>
          </div>
          <div class="form-group col-4">
            <label>Asosiy Vosita</label>
            <v-autocomplete
              v-model="main_assets"
              :items="truckLists"
              outlined
              item-text="item"
              item-valu="id"
              dense
              return-object
              :error-messages="main_assetsErrors"
              @input="$v.main_assets.$touch()"
              @blur="$v.main_assets.$touch()"
            ></v-autocomplete>
          </div>
        </div>

        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <v-btn :disabled="truckReg" color="primary" @click="save">
            <i v-if="truckReg" class="el-icon-loading"></i> Yuborish</v-btn
          >
          <!-- <button type="button" class="btn btn-success" @click="save">
            Yuborish
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);
</style>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { maxLength, required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      truckReg: false,
      select: '',
      truck_type: '',
      driver: '',
      reg_number_region: '',
      reg_number: '',
      kuzov_number: '',
      kuzov_type: '',
      bought_date: '',
      main_assets: '',
      dvigatel_number: '',
      status: 'PUS',
      fields: { text: 'first', value: 'id' }
    }
  },
  validations: {
    truck_type: {
      required
    },
    reg_number_region: {
      required
    },
    reg_number: {
      required,
      maxLength: maxLength(6)
    },
    kuzov_number: {
      required
    },
    kuzov_type: {
      required
    },
    dvigatel_number: {
      required
    },
    bought_date: {
      required
    },
    main_assets: {
      required
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllDrivers')
    this.$store.dispatch('getAllTruckTypes')
    this.$store.dispatch('notLinkedTotruck')
  },
  computed: {
    truck_typeErrors() {
      const errors = []
      if (!this.$v.truck_type.$dirty) return errors

      !this.$v.truck_type.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_number_regionErrors() {
      const errors = []
      if (!this.$v.reg_number_region.$dirty) return errors

      !this.$v.reg_number_region.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_numberErrors() {
      const errors = []
      if (!this.$v.reg_number.$dirty) return errors
      if (!this.$v.reg_number.required) {
        errors.push("To'ldirilishi shart bo'lgan qator")
      } else if (!this.$v.reg_number.maxLength) {
        errors.push('Maksimum 6 ta harf')
      }

      return errors
    },
    kuzov_numberErrors() {
      const errors = []
      if (!this.$v.kuzov_number.$dirty) return errors

      !this.$v.kuzov_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    kuzov_typeErrors() {
      const errors = []
      if (!this.$v.kuzov_type.$dirty) return errors

      !this.$v.kuzov_type.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    dvigatel_numberErrors() {
      const errors = []
      if (!this.$v.dvigatel_number.$dirty) return errors

      !this.$v.dvigatel_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    bought_dateErrors() {
      const errors = []
      if (!this.$v.bought_date.$dirty) return errors

      !this.$v.bought_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    main_assetsErrors() {
      const errors = []
      if (!this.$v.main_assets.$dirty) return errors

      !this.$v.main_assets.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    truckLists() {
      const data = this.$store.state.requests.notLinkedTotruck
      data.forEach((element) => {
        element.item =
          'Inventar nomeri' +
          ' ' +
          element.inventar_number +
          ', ' +
          'Nomi: ' +
          element.name +
          ', Summasi: ' +
          element.total_amount
      })
      return data
    },
    allDriversNames() {
      const data = this.$store.state.requests.allDrivers
      return data
    },
    allTruckTypes() {
      const data = this.$store.state.requests.allTruckTypes
      data.forEach((x) => {
        x.name = x.brand.name + ' ' + x.model
      })
      return data
    }
  },
  watch: {
    reg_number: function (val) {
      // console.log(val)
      this.reg_number = val.toUpperCase()
    },
    kuzov_number: function (val) {
      // console.log(val)
      this.kuzov_number = val.toUpperCase()
    },
    kuzov_type: function (val) {
      // console.log(val)
      this.kuzov_type = val.toUpperCase()
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Transport' },

      { title: 'Mashina registratsiyasi' }
    ])
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          main_assets: this.main_assets.id,
          truck_type: this.truck_type,
          driver: this.driver,
          reg_number_region: this.reg_number_region,
          reg_number: this.reg_number,
          kuzov_number: this.kuzov_number,
          kuzov_type:
            this.kuzov_type.charAt(0).toUpperCase() + this.kuzov_type.slice(1),
          bought_date: this.bought_date.split('-').reverse().join('-'),
          dvigatel_number: this.dvigatel_number,
          status: this.status
        }
        this.truckReg = true
        this.$store
          .dispatch('createTruck', data)
          .then(() => {
            this.truckReg = false
          })
          .catch((err) => {
            this.truckReg = false
            console.error(err)
          })
      }
    }
  }
}
</script>

<style scoped>
.kuzov_type /deep/ input {
  text-transform: capitalize;
}
.form-group {
  margin-bottom: 0;
  padding-left: 0;
}
.card-body {
  display: flex;
  flex-wrap: wrap;
}
</style>
